import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getprofile, signoutUser } from "../../Reducers/auth/actions";
import { fetchprofile } from "../../Pages/profile/Reducers/actions";
import { message } from "../../Reducers/loading/actions";
import { Search } from "../../Pages/Items/Reducers/items/actions";
import { fetchbedrijven } from "../../Pages/Admin/Bedrijven/Reducers/actions";
import SearchDropdown from "../../Components/Main/SearchDropdown"; // Importeer het SearchDropdown-component

const Header = ({ username, profile, items, getprofile, signoutUser, message, fetchprofile, fetchbedrijven, Search }) => {
  const dropdownRef = useRef(null);
  const [dropdownMenu, setdropdownMenu] = useState(false);
  useEffect(() => {
    getprofile().then((data) => {
      if (data && data.type === "auth_error") {
        signoutUser();
      }
    });
    fetchprofile();
    fetchbedrijven();
  }, [getprofile, signoutUser, fetchprofile, fetchbedrijven]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setdropdownMenu(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (username.bedrijf && username.bedrijf) {
      document.title = "Dashboard van " + username.bedrijf.naam;
    } else {
      document.title = "Dashboard";
    }
  }, [username]);

  const logout = () => {
    signoutUser();
    message(3000, "info", "Je bent succesvol uitgelogd");
  };
  const handleLinkClick = () => {
    setdropdownMenu(!dropdownMenu);
  };
  const opensidebar = () => {
    const body = document.querySelector("body");
    const screenWidth = window.innerWidth;

    if (screenWidth > 576) {
      body.classList.toggle("sidebar-collapse");
    } else {
      body.classList.toggle("sidebar-open");
    }
  };
  const Profileimage = () => {
    if (profile && profile.portimage) {
      return <img src={profile.portimage} title="Logo Recraplan" className="img-circle" alt="Gebuiker foto" />;
    } else {
      return <img src="/img/nofoto.png" title="Logo Recraplan" className="img-circle" alt=" foto" />;
    }
  };

  return (
    <header className="main-header">
      <Link to="/" className="logo">
        <span className="logo-mini">
          <img src="/brand/logo_small.png" alt="Logo Recraplan" height={55} />
        </span>
        <span className="logo-lg">
          <img src="/brand/logo.png" alt="Logo Recraplan" height={55} />
        </span>
      </Link>

      <nav className="navbar navbar-expand-lg navbar-light">
        {/* Sidebar toggle button */}
        <a className="sidebar-toggle" data-bs-toggle="sidebar" role="button" onClick={opensidebar}>
          <span className="sr-only">Toggle navigation</span>
          <span className="fas fa-bars"></span>
        </a>
        {username.bedrijf && username.bedrijf.modules.includes("items") && <SearchDropdown items={items} Search={Search} />}

        <div className="navbar-custom-menu" ref={dropdownRef}>
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <Link to="#" onClick={handleLinkClick} className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span className="hidden-xs">
                  {username.voornaam} {username.achternaam}
                </span>
              </Link>
              <ul className={`dropdown-menu ${dropdownMenu ? "show" : ""}`} aria-labelledby="navbarDropdown" ref={dropdownRef}>
                <li className="dropdown-item">
                  {/* User image */}
                  <Profileimage />
                  <p></p>
                </li>
                <li className="dropdown-item d-flex justify-content-around">
                  <div>
                    <Link to="/admin/profile" className="btn btn-default btn-flat">
                      Profiel
                    </Link>
                  </div>
                  <div>
                    <Link to="/login" onClick={logout} className="btn btn-default btn-flat">
                      Uitloggen
                    </Link>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

const mapStateToProps = (state) => {
  return { errors: state.auth.error, username: state.auth.user, profile: state.profile, items: state.items.items };
};

export default connect(mapStateToProps, { fetchprofile, getprofile, signoutUser, message, fetchbedrijven, Search })(Header);
