import React from "react";

import { Field, reduxForm, FieldArray } from "redux-form";
import { renderField, renderselect, renderselectobject } from "../../../../../Components/Forms/renders";
import Wysiwyg from "../../../../../Components/Forms/wysiwyg";
import Tags from "../../.././../../Components/Forms/tags";
import MediaForm from "./media";

function velden(subitem, field, taal, transLate) {
  const fieldnaam = `${field}.${subitem.naam}`;
  if (subitem.soort === "text") {
    return (
      <div className="fieldfortranslate">
        <Field name={fieldnaam} type="input" component={renderField} label={`${subitem.desc} met naam ${subitem.naam}`} />
        {taal && (
          <div className="translatebutton float-right">
            <button type="button" className="btn btn-secondary btn-sm" id={fieldnaam} onClick={transLate(fieldnaam)}>
              Vertaal via ChatGPT
            </button>
          </div>
        )}
      </div>
    );
  } else if (subitem.soort === "media") {
    return <Field name={fieldnaam} type="input" component={MediaForm} label={`${subitem.soort} met naam ${subitem.naam}`} />;
  } else if (subitem.soort === "richttext") {
    return (
      <div className="fieldfortranslate">
        <Field name={fieldnaam} type="input" component={Wysiwyg} label={`${subitem.desc} met naam ${subitem.naam}`} />
        {taal && (
          <div className="translatebutton float-right">
            <button type="button" id={fieldnaam} className="btn btn-secondary btn-sm " onClick={transLate(fieldnaam)}>
              Vertaal via ChatGPT
            </button>
          </div>
        )}
      </div>
    );
  } else if (subitem.soort === "button") {
    return (
      <div className="card">
        <div className="card-header">{`${subitem.soort} met naam ${subitem.naam}`}</div>
        <div className="card-body">
          <div className="fieldfortranslate">
            <Field name={fieldnaam + ".title"} type="input" component={renderField} label="Text Button" />
            {taal && (
              <div className="translatebutton float-right">
                <button type="button" className="btn btn-secondary btn-sm" id={fieldnaam + ".title"} onClick={transLate(fieldnaam + ".title")}>
                  Vertaal via ChatGPT
                </button>
              </div>
            )}
          </div>
          <Field name={fieldnaam + ".url"} type="input" component={renderField} label="Url Button" />
          <Field
            className="form"
            name={fieldnaam + ".target"}
            label="Target van de link"
            component={renderselect}
            options={{
              _self: "Hetzelfde venster",
              _blank: "Nieuw tabblad",
              _top: "Nieuw venster",
            }}
          />
        </div>
      </div>
    );
  } else if (subitem.soort === "link") {
    return (
      <div className="card">
        <div className="card-header">{`${subitem.soort} met naam ${subitem.naam}`}</div>
        <div className="card-body">
          <div className="fieldfortranslate">
            <Field name={fieldnaam + ".title"} type="input" component={renderField} label="Text Button" />
            {taal && (
              <div className="translatebutton float-right">
                <button type="button" className="btn btn-secondary btn-sm" id={fieldnaam + ".title"} onClick={transLate(fieldnaam + ".title")}>
                  Vertaal via ChatGPT
                </button>
              </div>
            )}
          </div>
          <Field name={fieldnaam + ".url"} type="input" component={renderField} label="Url Link" />
          <Field
            className="form"
            name={fieldnaam + ".target"}
            label="Target van de link"
            component={renderselect}
            options={{
              _self: "Hetzelfde venster",
              _blank: "Nieuw tabblad",
              _top: "Nieuw venster",
            }}
          />
        </div>
      </div>
    );
  } else if (subitem.soort === "enumeration") {
    return <Field name={fieldnaam} type="input" options={subitem.options ? subitem.options : []} component={renderselectobject} label={`${subitem.soort} met naam ${subitem.naam}`} />;
  } else if (subitem.soort === "tag") {
    return (
      <div className="fieldfortranslate">
        <Field name={fieldnaam} type="input" options={subitem.options ? subitem.options : []} component={Tags} label={`${subitem.soort} met naam ${subitem.naam}`} />
        {taal && (
          <div className="translatebutton float-right">
            <button type="button" className="btn btn-secondary btn-sm" id={fieldnaam} onClick={transLate(fieldnaam, "tag")}>
              Vertaal via ChatGPT
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default function ArrayVeldenSub({ fields, transLate, subitem, taal }) {
  return (

    <div className="box box-info p-4 websubtable">
      {fields.map((field, index) => (
        <div style={{ display: "grid" }} key={index}>
          {subitem.subcontent.map((item, index) => (
            <div key={index}>{velden(item, field, taal, transLate)}</div>
          ))}
          <button className="btn btn-warning btn-sm float-end m-2" type="button" title="Remove Member" onClick={() => fields.remove(index)}>
            Veld verwijderen
          </button>
        </div>
      ))}
      <button className="btn btn-success " type="button" onClick={() => fields.push({})}>
        Nieuw veld
      </button>
    </div>
  );
}
