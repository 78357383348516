import React, { useEffect, useState } from "react";
import { Field, reduxForm, getFormValues } from "redux-form";
import { required } from "redux-form-validators";
import { connect } from "react-redux";
import { renderField, renderselapi, renderselect, renderTextField } from "../../../Components/Forms/renders";

const InsertGastForm = ({ handleSubmit }) => {
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-12">
              <Field name="email" type="email" component={renderField} label="Email" validate={required({ msg: "Dit item is verplicht" })} />
              <Field name="voornaam" type="voornaam" label="Voornaam" component={renderField} />
              <Field name="tussennaam" type="tussennaam" label="Tussennaam" component={renderField} />
              <Field name="achternaam" type="achternaam" label="Achternaam" component={renderField} />
            </div>
          </div>
        </div>
        <div className="pull-left submit">
          <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(
  reduxForm({
    form: "insertgasten",
  })(InsertGastForm)
);
