import React, { useState, useEffect } from "react";
import { Field, reduxForm, FieldArray, change } from "redux-form";
import { connect } from "react-redux";
import Velden from "./Form/velden";
import ArrayVelden from "./Form/arrayvelden";
import { vertalen } from "../../Reducers/actions";

const LogboekFormWebsite = ({ handleSubmit, buildElements, taal, initialValues, dispatch, vertalen, settiings }) => {
  const [loading, setLoading] = useState(true);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [expanded, setExpanded] = useState({});

  const toggleExpand = (itemIndex) => {
    setExpandedIndex((prevIndex) => (prevIndex === itemIndex ? null : itemIndex));
  };
  useEffect(() => {
    const asyncOperations = async () => {
      for (const item of buildElements) {
        for (const subitem of item.componentId.content) {
          if (subitem.groeparray == "true") {
            const fieldname = taal ? `content.${taal}.${item.componentId.naam}.${subitem.naam}` : `content.${item.componentId.naam}.${subitem.naam}`;
            const dynamicValue = fieldname.split(".").reduce((value, key) => value && value[key], initialValues);

            if (!Array.isArray(dynamicValue)) {
              await dispatch(change("page_builder_edit", fieldname, []));
            }
          }
        }
      }
      setLoading(false);
    };

    asyncOperations();
  }, [dispatch, buildElements, taal, initialValues]);

  const transLate = (fieldnaam, soort) => async () => {
    const button = document.getElementById(fieldnaam);
    button.innerText = "loading";
    const strippedPath = fieldnaam.replace(`${taal}.`, "");
    const pathParts = strippedPath.split(/[.\[\]]+/).filter(Boolean);

    let value = initialValues;
    for (const part of pathParts) {
      if (value[part] === undefined) {
        console.log(`Path not found: ${strippedPath}`);
        button.innerText = "Geen vertaling mogelijk";
        return;
      }
      value = value[part];
    }
    const talen = {
      nl: "Nederlands",
      en: "Engels",
      de: "Duits",
      fr: "Frans",
    };

    const message = `Vertaal de volgende tekst vanuit het Nederlands naar ${talen[taal]}. Return alleen de vertaling en geen beschrijving in het formaat: ${typeof value}.  De tekst om te vertalen is: ${value}`;

    vertalen({ message }).then((res) => {
      if (soort === "tag") {
        const array = res.split(", ").map((item) => item.trim());
        dispatch(change("page_builder_edit", fieldnaam, array));
      } else {
        dispatch(change("page_builder_edit", fieldnaam, res));
      }
      button.innerText = "Vertaal via ChatGPT";
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      {loading ? (
        <div>Loading...</div>
      ) : (
        buildElements.map((item, itemindex) => {
          return (
            <div className="card mb-4" key={itemindex}>
              <div className="card-header with-border">
                {item.componentId.naam}
                <button
                  type="button"
                  onClick={() => toggleExpand(itemindex)}
                  className="expand-button"
                  style={{ float: "right" }} // Voorbeeld styling, pas dit aan volgens je CSS
                >
                  {expanded[itemindex] ? "-" : "+"}
                </button>
              </div>
              {expandedIndex === itemindex && (
                <div className="card-body p-2">
                  {item.componentId.content.map((subitem, index) => {
                    return (
                      <div className="card" key={index}>
                        <div className="card-body">
                          {subitem.groepsarray == true ? (
                            <FieldArray
                              initialValues={initialValues}
                              name={taal ? `content.[${itemindex}]${taal}.${item.componentId.naam}.${subitem.naam}` : `components[${itemindex}].content.${item.componentId.naam}.${subitem.naam}`}
                              component={ArrayVelden}
                              item={item}
                              subitem={subitem}
                            />
                          ) : (
                            <Velden
                              fieldnaam={taal ? `components[${itemindex}].content.${taal}.${item.componentId.naam}.${subitem.naam}` : `components[${itemindex}].content.${item.componentId.naam}.${subitem.naam}`}
                              item={item}
                              subitem={subitem}
                              taal={taal}
                              itemindex={itemindex}
                              transLate={transLate}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })
      )}
      <div className="card mb-4">
        <div className="card-header with-border">
          SEO
          <button
            type="button"
            onClick={() => toggleExpand(100)}
            className="expand-button"
            style={{ float: "right" }} // Voorbeeld styling, pas dit aan volgens je CSS
          >
            {expanded[100] ? "-" : "+"}
          </button>
        </div>
        {expandedIndex === 100 && (
          <div className="card-body">
            <Velden
              fieldnaam={taal ? `seo.${taal}.title` : `seo.title`}
              taal={taal}
              transLate={transLate}
              subitem={{
                soort: "text",
                desc: "Tekstveld",
                naam: "Titel van de pagina",
              }}
            />
            <Velden
              fieldnaam={taal ? `seo.${taal}.desc` : `seo.desc`}
              taal={taal}
              transLate={transLate}
              subitem={{
                soort: "text",
                desc: "Tekstveld",
                naam: "Beschrijving van de pagina",
              }}
            />
            <Velden
              fieldnaam={taal ? `seo.${taal}.zoekwoorden` : `seo.zoekwoorden`}
              taal={taal}
              transLate={transLate}
              subitem={{
                soort: "text",
                desc: "Tekstveld",
                naam: "Zoekwoorden",
              }}
            />
          </div>
        )}
      </div>
      <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
    </form>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "page_builder_edit", // a unique identifier for this form
})(LogboekFormWebsite);

const mapStateToProps = (state) => ({
  initialValues: state.cms.pages.item, // pull initial values from account reducer
});

export default connect(mapStateToProps, { vertalen })(ReduxFormSensorEdit);
