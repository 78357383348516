import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { fetshsensoren, getaccommodatie, updateaccommodaties, fetchstanplaatscategorien } from "../Reducers/actions";

import EditForm from "./Components/edit";

const AccommodatieEditInsert = () => {
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const soortsensoren = useSelector((state) => state.standplaatsen.sensoren);
  const categorien = useSelector((state) => state.standplaatsen.categorien);

  const submit = (vals) => {
    dispatch(updateaccommodaties(params.id, vals)).then((data) => {
      if (data.type === "SENSOR_ERROR") {
        alert(data.payload);
        return false;
      } else {
        navigate("/standplaatsen/accommodaties");
      }
    });
  };

  useEffect(() => {
    dispatch(getaccommodatie(params.id)).then(() => {
      dispatch(fetshsensoren()).then(() => {
        dispatch(fetchstanplaatscategorien()).then(() => {
          setLoaded(true);
        });
      });
    });
  }, [dispatch, params.id]);

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Accommodatie wijzigen</h3>
      </div>

      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">{loaded && <EditForm categorien={categorien} sensoren={soortsensoren} onSubmit={submit} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccommodatieEditInsert;
