import React, { useEffect } from "react";
import { connect } from "react-redux";
import { saveervaringen } from "./Reducers/actions";
import LogboekForm from "./Components/insert";
import { message } from "../../Reducers/loading/actions";
import {Link, useNavigate} from 'react-router-dom';
import ErrorMessage from "../../Components/loading/error";
const ErvaringenInsertPage = ({ saveervaringen, message, error }) => {
  const navigate = useNavigate
  const submit = (vals) => {
    saveervaringen(vals).then((data) => {
      if (data.type === "FACILITEIT_ERROR") {
        alert(data.payload);
        return false;
      } else {
        message(2000, "success", "De faciliteit is succesvol verwerkt").then((data) => {
          navigate("/ervaringen/");
        });
      }
    });
  };

  return (
    <div className="box box-default">
    <div className="box-header with-border">
      <h3 className="box-title">Nieuw Ervaring Toevoegen</h3>
    </div>
    <ErrorMessage fout={error} />
    <div className="box-body wizard">
      <ul className="nav nav-justified thumbnail setup-panel">
        <li className="active flex-item">
          <Link to="/items/add">
            Wat
          </Link>
        </li>
        <li className="flex-item disabled">
          <span>Waar</span>
        </li>
        <li className="flex-item disabled">
          <span>Wanneer</span>
        </li>
        <li className="flex-item disabled">
          <span>Media</span>
        </li>
        <li className="flex-item disabled">
          <span>Eigenschappen</span>
        </li>
      </ul>
      <LogboekForm onSubmit={submit} />
    </div>
  </div>
  );
};

const mapStateToProps = (state) => ({ errors: state.auth.error,  error: state.items.error });

export default connect(mapStateToProps, {
  saveervaringen,
  message,
})(ErvaringenInsertPage);


