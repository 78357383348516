import {
  GET_ARRANGEMENTEN,
  ADD_ARRANGEMENTEN,
  ERROR_ARRANGEMENTEN,
  SET_ARRANGEMENTEN
} from './actions';
export default function arrangementen(state = {
  items: [],
  error: {},
  item: []
}, action = {}) {
  switch (action.type) {
    case ADD_ARRANGEMENTEN:
      return { ...state,
        items: [...state.itens, action.results]
      };
    case ERROR_ARRANGEMENTEN:
      return { ...state,
        error: action.payload
      };
    case GET_ARRANGEMENTEN:
      return { ...state,
        items: action.results
      };
    case SET_ARRANGEMENTEN:
        return { ...state,
          item: action.results
        };
    default:
      return state;
  }
}
