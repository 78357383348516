export const GET_BOEKINGEN = "GET_BOEKINGEN";
export const ADD_BOEKINGEN = "ADD_BOEKINGEN";
export const SET_BOEKINGEN = "SET_BOEKINGEN";
export const ERROR_BOEKINGEN = "ERROR_BOEKINGEN";

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    return response.json().then((json) => {
      throw json.errors;
    });
  }
}

export function add(results) {
  return {
    type: ADD_BOEKINGEN,
    results,
  };
}
export function get(results) {
  return {
    type: GET_BOEKINGEN,
    results,
  };
}
export function set(results) {
  return {
    type: SET_BOEKINGEN,
    results,
  };
}
export function arrangementenError(error) {
  return {
    type: ERROR_BOEKINGEN,
    payload: error,
  };
}
export function saveboekingen(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/boekingen", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(add(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function updateboekingen(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/boekingen/" + id, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(set(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function updatepersonen(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/boekingen/personen/" + id, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(set(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function updateklant(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/boekingen/klant/" + id, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(set(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function fetchboekingenbyervaring(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/boekingen/ervaringen", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(get(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}


export function fetchboekingen(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/boekingen/status", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(get(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function getboekingen(id) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/boekingen/" + id, {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(set(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}
export function delboekingen(id) {
  return async (dispatch) => {
    try {
      const res = await fetch("/api/admin/boekingen/" + id, {
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return dispatch(set(data.results));
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function annuleerboekingen(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/boekingen/annuleerboekingen", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(get(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function verzendlijst(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/boekingen/verzendlijst", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(get(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function controleerboekingen(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/boekingen/controleren", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(get(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function terugstoren(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/boekingen/terugebetaling", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(set(results.results));
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function mail(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/boekingen/mail", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      return results;
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}