import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchsettings, fetchcomponents, fetchPageTemplate } from "../../Reducers/actions";
import { message } from "../../../../Reducers/loading/actions";

function SubMenu({ fetchsettings, settings, fetchcomponents, components, fetchPageTemplate, templates }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchsettings().then((res) => {
      fetchcomponents().then((res) => {
        fetchPageTemplate().then((res) => {
          setLoading(true);
        });
      });
    });
  }, [fetchsettings]);
  return (
    <div className="sub-sidebar flex-shrink-0 p-1 bg-white">
      <a href="/" className="d-flex align-items-center pb-3 mb-3 link-dark text-decoration-none border-bottom">
        <span className="fs-5 fw-semibold">Setting menu</span>
      </a>
      <ul className="list-unstyled ps-0">
        <li className="mb-1">
          <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#pagina-collapse" aria-expanded="true">
            <i className="fa fa-book" /> Pagina's
          </button>
          <div className="collapse show" id="pagina-collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
              {settings &&
                settings.website &&
                settings.website.map((page, inddex) =>
                  page.sub && page.sub.length > 0 ? (
                    <li key={inddex}>
                      <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target={`#pagina-${page.naam}`} aria-expanded="true">
                        <i className="fa fa-book" /> {page.naam}
                      </button>
                      <div className="collapse show" id={`pagina-${page.naam}`}>
                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                          {page.sub.map((sub, index) => (
                            <li key={index}>
                              <Link to={`/cms/pages?slug=${page.slug}${sub.slug}&naam=${page.naam}-${sub.naam}&pageType=pagina`} className="link-dark rounded">
                                {sub.naam}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                  ) : (
                    <li key={page.naam}>
                      <Link to={`/cms/pages?slug=${page.slug}&naam=${page.naam}&pageType=pagina`} className="link-dark rounded">
                        {page.naam}
                      </Link>
                    </li>
                  )
                )}
            </ul>
          </div>
        </li>
        <li className="mb-1">
          <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#onderdelen-collapse" aria-expanded="true">
            <i className="fa fa-book" /> Onderdelen
          </button>
          <div className="collapse show" id="onderdelen-collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
              {settings && settings.onderdelen &&
                settings.onderdelen.map((page, index) => (
                  <li key={index}>
                     <Link to={`/cms/pages?slug=false&naam=${page.onderdeel}&pageType=onderdeel`} className="link-dark rounded">
                        {page.onderdeel}
                      </Link>
                  </li>
                ))}
            </ul>
          </div>
        </li>
        <li className="mb-1">
          <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#template-collapse" aria-expanded="true">
            <i className="fa fa-book" /> Templates
          </button>
          <div className="collapse" id="template-collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
              {templates &&
                templates.map((template, index) => (
                  <li key={index}>
                    <Link to={`/cms/types/templates/edit/${template._id}`} className="link-dark rounded">
                      {template.naam}
                    </Link>
                  </li>
                ))}
              <hr />
              <li>
                <Link to={"/cms/types/templates/new"} className="link-dark rounded">
                  Nieuw
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li className="mb-1">
          <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#componenten-collapse" aria-expanded="true">
            <i className="fa fa-book" /> Componenten
          </button>
          <div className="collapse" id="componenten-collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
              {components &&
                components.map((component) => (
                  <li key={component.naam}>
                    <Link to={`/cms/types/componenten/edit/${component._id}`} className="link-dark rounded">
                      {component.naam}
                    </Link>
                  </li>
                ))}
              <hr />
              <li>
                <Link to={"/cms/types/componenten/new"} className="link-dark rounded">
                  Nieuw
                </Link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
}

function mapStateToProps(state) {
  return { settings: state.cms.settings, components: state.cms.components.items, templates: state.cms.templates.items };
}

export default connect(mapStateToProps, {
  fetchsettings,
  fetchcomponents,
  fetchPageTemplate,
})(SubMenu);
