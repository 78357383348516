import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const MyComponent = ({ newlatlon, replacemap }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "YOUR_API_KEY",
  });

  const [map, setMap] = useState(null);
  const [containerStyle, setContainerStyle] = useState({
    width: "100%",
    height: "500px",
  });

  const [center, setCenter] = useState({
    lat: 37.772,
    lng: -122.214,
  });

  useEffect(() => {
    if (newlatlon) {
      setCenter({
        lat: Number(newlatlon.lat),
        lng: Number(newlatlon.lon),
      });
    }
  }, [newlatlon]);

  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);

      setMap(map);
    },
    [center]
  );

  const onLoadMarker = (marker) => {
    if (marker && marker.latLng) {
      var lng = marker.latLng.lng();
      var lat = marker.latLng.lat();
      replacemap({ lat: lat, lon: lng });
    }
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={16} onUnmount={onUnmount}>
      <Marker onLoad={onLoadMarker} position={center} draggable={true} onDragEnd={onLoadMarker} />
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(MyComponent);
