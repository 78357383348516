import React, { useState, useEffect } from "react";
import Switch from "react-switch";

const SwitcButton = ({ label, input }) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    if (input.value) {
      setValue(input.value);
    }
  }, [input.value]);

  const handleSelect = (newValue) => {
    setValue(newValue);
    input.onChange(newValue);
  };
  if (label) {
    return (
      <div>
        <label>{label}</label>
        <div>
          <Switch onChange={handleSelect} checked={value} />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Switch onChange={handleSelect} checked={value} />
      </div>
    );
  }
};

export default SwitcButton;
