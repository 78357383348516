export const GET_ERVARINGEN = "GET_ERVARINGEN";
export const ADD_ERVARINGEN = "ADD_ERVARINGEN";
export const SET_ERVARINGEN = "SET_ERVARINGEN";
export const SET_BESCHIKBAARHEID = "SET_BESCHIKBAARHEID";
export const GET_BESCHIKBAARHEID = "GET_BESCHIKBAARHEID";
export const ADD_BESCHIKBAARHEID = "ADD_BESCHIKBAARHEID";
export const ERROR_ERVARINGEN = "ERROR_ERVARINGEN";
export const SET_BESCHIKBAARHEIDEN = "SET_BESCHIKBAARHEIDEN";
export const SET_ALLEDAGEN = "SET_ALLEDAGEN";

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}

export function add(results) {
  return {
    type: ADD_ERVARINGEN,
    results,
  };
}
export function get(results) {
  return {
    type: GET_ERVARINGEN,
    results,
  };
}
export function set(results) {
  return {
    type: SET_ERVARINGEN,
    results,
  };
}
export function setbeschikbaarheid(results) {
  return {
    type: SET_BESCHIKBAARHEID,
    results,
  };
}

export function setbeschikbaarheiden(results) {
  return {
    type: SET_BESCHIKBAARHEIDEN,
    results,
  };
}

export function arrangementenError(error) {
  return {
    type: ERROR_ERVARINGEN,
    payload: error,
  };
}
export function setalledagen(payload) {
  return {
    type: SET_ALLEDAGEN,
    payload,
  };
}
export function saveervaringen(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/ervaringen", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(add(results.results));
      return results;
    } catch (err) {
      dispatch(arrangementenError(err));
    }
  };
}

export function updateervaringen(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/ervaringen/" + id, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(add(results.results));
      return results;
    } catch (err) {
      dispatch(arrangementenError(err));
    }
  };
}

export function fetchervaringen() {
  return async (dispatch) => {
    try {
      const res = await fetch("/api/admin/ervaringen/", {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return dispatch(get(data.results));
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function fetchervaringenaankoemende() {
  return async (dispatch) => {
    try {
      const res = await fetch("/api/admin/ervaringen/beschikbaarheid/aankomende", {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return dispatch(setbeschikbaarheiden(data.results));
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function getervaringen(id) {
  return async (dispatch) => {
    try {
      const res = await fetch("/api/admin/ervaringen/" + id, {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return dispatch(set(data.result));
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function getervaringenall(id) {
  return async (dispatch) => {
    try {
      const res = await fetch("/api/admin/ervaringen/all/" + id, {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return dispatch(set(data.result));
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}
export function delervaringen(id) {
  return async (dispatch) => {
    try {
      const res = await fetch("/api/admin/ervaringen/" + id, {
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return dispatch(set(data.results));
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function savebeschikbaarheid(data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/ervaringen/beschikbaarheid", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(add(results.results));
      return results;
    } catch (err) {
      dispatch(arrangementenError(err));
    }
  };
}

export function getbeschikbaarheid(id) {
  return async (dispatch) => {
    try {
      const res = await fetch("/api/admin/ervaringen/beschikbaarheid/get/" + id, {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return dispatch(setbeschikbaarheid(data.results));
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function controleerbeschikbaarheid(id) {
  return async (dispatch) => {
    try {
      const res = await fetch("/api/admin/ervaringen/beschikbaarheid/controleren/" + id, {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return dispatch(setbeschikbaarheid(data.results));
    } catch (err) {
      return dispatch(arrangementenError(err));
    }
  };
}

export function updatebeschikbaarheid(id, data) {
  return async (dispatch) => {
    try {
      const response = await fetch("/api/admin/ervaringen/beschikbaarheid/" + id, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      const results = await handleResponse(response);
      dispatch(setbeschikbaarheid(results.results));
      return results;
    } catch (err) {
      dispatch(arrangementenError(err));
    }
  };
}
