import React from "react";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import UploadForm from "../../../Components/Forms/uploadGallery";

import SortableComponent from "../../../Components/Gallery/sort";
import FooterForm from "../../../Components/Lib/FooterForm";
import { renderField } from "../../../Components/Forms/renders";

class SyncValidationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      visible: false,
    };
  }
  show = () => {
    this.setState({ visible: true });
  };

  hide() {
    this.setState({ visible: false });
  }
  render() {
    const { onSubmit, item } = this.props;
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={item}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <h4 className="info-text">Media gegevens.</h4>
              </div>
            </div>
            <div className="box">
              <div className="box-header with-border">
                <h3 className="box-title">Klik hier om de foto's te uploaden </h3>
                <button type="button" className="btn btn-primary btn float-right uploadbtn" onClick={this.show.bind(this)}>
                  Upload bestanden
                </button>
              </div>
              <div className="box-body">
                <div className="box">
                  <Field name="images" component={SortableComponent} />
                </div>
                <Rodal visible={this.state.visible} onClose={this.hide.bind(this)} width={800} height={500}>
                  <Field name="images" ItemId={item._id} onClose={this.hide.bind(this)} component={UploadForm} label="Kies bestanden" isModal={true} />
                </Rodal>
              </div>
            </div>

            <div className="box">
              <div className="box-header with-border">
                <h3 className="box-title">Youtube Video</h3>
              </div>
              <div className="box-body">
                <Field name="media.video.url" label="URL van Youtube Video" component={renderField} />
              </div>
            </div>

            <div className="box">
              <div className="box-header with-border">
                <h3 className="box-title">Skyfocus</h3>
              </div>
              <div className="box-body">
                <Field name="media.tours.url" label="URL van skyfocus" component={renderField} />
              </div>
            </div>
            <FooterForm />
          </form>
        )}
      />
    );
  }
}

// You have to connect() to any Reducers that you wish to connect to yourself
SyncValidationForm = connect(
  (state) => ({
    item: state.items.item, // pull initial values from account reducer
  }) // bind account loading action creator
)(SyncValidationForm);

export default SyncValidationForm;
