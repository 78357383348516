import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Fetcharrangementen, SetArrangemententoEdit } from '../Reducers/arrangementen/actions';

const ArrangementenPage = ({ arrangementen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Fetcharrangementen());
  }, [dispatch]);

  const handleItem = (item) => {
    dispatch(SetArrangemententoEdit(item));
    navigate("/items/arrangementen/edit/" + item._id);
  };

  return (
    <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title">Arrangementen</h3>
          <div className="box-tools pull-right">
            <Link
              to="/items/arrangementen/add"
              type="button"
              className="btn-primary btn btn-box-too btn-sm"
            >
              <i className="fa fa-plus"></i>
              &nbsp; Nieuwe Arrangementen Toevoegen
            </Link>
          </div>
        </div>
        <div className="box-body">
          <div className="box-header with-border">
            <h3 className="box-title">Dit zijn de Arrangementen</h3>
          </div>
          <div className="box-body">
            <div className="box-body table-responsive no-padding">
              <table className="table table-hover">
                <tbody>
                  <tr>
                    <th>Titel</th>
                    <th>Beschrijving</th>
                  </tr>
                  {arrangementen.map((app) => (
                    <tr
                      key={app._id}
                      onClick={() => {
                        handleItem(app);
                      }}
                    >
                      <td>{app.titel}</td>
                      <td>{app.beschrijving.replace(/(<([^>]+)>)/gi, "").substring(0, 100)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

function mapStateToProps(state) {
  return {
    errors: state.theme.error,
    arrangementen: state.arrangementen.items,
  };
}
export default connect(mapStateToProps, {
  Fetcharrangementen,
  SetArrangemententoEdit,
})(ArrangementenPage);
