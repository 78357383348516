import "rc-time-picker/assets/index.css";

import React, { useState } from "react";
import { required } from "redux-form-validators";
import { Field } from "redux-form";
import SelectBox from "../../../../Components/Forms/selectbox";
import { renderselect } from "../../../../Components/Forms/renders";

const MeterForm = ({ fields, categorien, sensoren }) => {
  return (
    <div className="box box-default mx-2 mt-2 p-4">
      {fields.map((meter, index) => (
        <div key={index}>
          <div className="box-header with-border">
            <h3 className="box-title"> Meter {index + 1}</h3>
            <div className="box-tools pull-right">
              <button onClick={() => fields.remove(index)}>
                <i className="fa fa-minus-circle"></i>
              </button>
            </div>
          </div>
          <Field
            className="form"
            name={`${meter}.soort`}
            validate={required({ msg: "Type item is verplicht" })}
            label="Soort Sensor"
            component={renderselect}
            options={{
              ampere: "Ampere",
              electra: "Electra",
              gas: "Gas",
              temperatuur: "Temperatuur",
              water: "Water",
            }}
          />
          <Field name={`${meter}.categorie`} validate={[required()]} type="input" options={categorien} inputvalue="_id" inputnaam="naam" component={SelectBox} label="Vul de categorie van de accommodatie in" />
          <Field name={`${meter}.sensor_id`} validate={[required()]} type="input" options={sensoren} inputvalue="_id" inputnaam="naam" component={SelectBox} label="Vul de Sensor ID in" />
        </div>
      ))}
      <div className="box-footer">
        <button type="button" onClick={() => fields.push({})}>
          Meter Toevoegen
        </button>
      </div>
    </div>
  );
};

export default MeterForm;
