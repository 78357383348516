import React, { useState } from "react";

import SubMenu from "./Components/submenu";
import moment from "moment";
import { getonepages, savepages } from "../Reducers/actions";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { message, fixmessage } from "../../../Reducers/loading/actions";
import { useLocation } from "react-router-dom";
import ContentBuilder from "./Components/contentbuilder";
import { useEffect } from "react";
const ContentItem = ({ page, message, match, getonepages, savepages, settings }) => {
  const [buildElements, setBuildElements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [taal, setTaal] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    setLoading(false);
    const queryParams = new URLSearchParams(location.search);
    const taalkeuze = queryParams.get("taal");
    if (taalkeuze) {
      setTaal(taalkeuze);
    }
    getonepages(params.id).then((res) => {

      if (res.type === "GET_CMS_PAGE") {
        if (res.results.type === "page") {
          setBuildElements(res.results.pagetemmplate.content);
        } else {
          setBuildElements(res.results.components);
        }
        setLoading(true);
      }
    });
  }, [location.search, params.id]);



  const submit = (values) => {
    savepages(values).then((data) => {
      if (data.type === "GET_CMS_PAGE") {
        message(2000, "success", "De component is succesvol opgelsagen").then(() => {
          navigate("/cms/content/");
        });
      } else {
        message(4000, "danger", "Er is wat fout gegaan");
      }
    });
  };
  const changetaal = (e) => {
    const selectedLanguage = e.target.value;
    setTaal(selectedLanguage);
    const selectedLanguageObj = settings.talen.find((lang) => lang.taal === selectedLanguage);
    if (!selectedLanguageObj.default) {
      const newUrl = "/cms/content/" + params.id + "?taal=" + selectedLanguage;
      navigate(newUrl);
    } else {
      const newUrl = "/cms/content/" + params.id;
      navigate(newUrl);
    }
  };

  return (
    <div className="content-page types">
      <div className="box box-default settings">
        <div className="box-header with-border">
          <h3 className="box-title"> Content Manager</h3>
        </div>
        <div className="box-body">
          <SubMenu />
          <div className="content-wrapper">
            <div className="container-fluid">
              {loading && (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="box box-info">
                      <div className="box-body">
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="box">
                              <div className="box-header with-border">
                                <h3 className="box-title">{page.naam}</h3>
                              </div>
                              <div className="box-body">{buildElements.length > 0 && <ContentBuilder settings={settings} buildElements={buildElements} onSubmit={submit} taal={taal} />}</div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="box">
                              <div className="box-header with-border">
                                <h3 className="box-title">Instellingen</h3>
                              </div>
                              <div className="box-body">
                                <h6>Informatie</h6>
                                <hr />
                                <div className="row">
                                  <div className="col-6">
                                    <p>Gemaakt</p>
                                  </div>
                                  <div className="col-6 text-end">
                                    <p>{moment(page.date).format("DD-MM-YYYY HH:mm")}</p>
                                  </div>
                                  <div className="col-6">
                                    <p>Geupdate</p>
                                  </div>
                                  <div className="col-6 text-end">
                                    <p>{moment(page.updated).format("DD-MM-YYYY HH:mm")}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="box-header with-border">
                                <h3 className="box-title">Talen</h3>
                              </div>
                              <div className="box-body">
                                <select className="form-control" onChange={changetaal} value={taal}>
                                  {settings.talen.map((item, index) => (
                                    <option key={index} value={item.taal}>
                                      {item.taal}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { components: state.cms.components.items, templates: state.cms.templates.items, page: state.cms.pages.item, settings: state.cms.settings };
}

export default connect(mapStateToProps, {
  message,
  getonepages,
  savepages,
})(ContentItem);
